import { Injectable } from "@angular/core";
import { ActivatedRoute, Navigation, Params, Router } from "@angular/router";
import { Location } from '@angular/common';
import { AppConfig } from "../config";

@Injectable({ providedIn: 'root' })
export class NavHelper {
	private _currentQueryParams: Params;

	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
	) {
		this._route.queryParams.subscribe(params => {
			this._currentQueryParams = params;
		});

	}

	public replaceLocationState(url: string): void {
		this._router.navigateByUrl(url, { replaceUrl: true });
	}

	public goBack(): void {
		window.history.back();
	}

	public getCurrentUrl(): string {
		return this._router.url;
	}

	public getLastNavigation(): Navigation {
		return this._router.lastSuccessfulNavigation;
	}

	public getCurrentUrlWithoutParams(): string {
		return this._route.snapshot.url.map(segment => segment.path).join('/');
	}

	public removeQueryParam(paramName) {
		const url = this.getCurrentUrl();

		if (url.indexOf('?') === -1 && url.indexOf('&') === -1) {
			return url; // Если нет параметров, возвращаем исходный URL без изменений
		}

		const [baseUrl, queryString] = url.split('?');

		const parameters = queryString.split('&');

		const filteredParameters = parameters.filter(param => {
			const [name] = param.split('=');
			return name !== paramName;
		});

		const newUrl = baseUrl + (filteredParameters.length > 0 ? '?' + filteredParameters.join('&') : '');
		this.replaceLocationState(newUrl);
		return newUrl;
	}

	public clearQueryObject(): void {
		this._currentQueryParams = {};
	}

	public changeQueryParam(paramName: string[], newParamValue: string[]): string {
		let currentURL = this.getCurrentUrl();

		for (let i = 0; i < paramName.length; i++) {
			const regex = new RegExp('([?&])' + paramName[i] + '=[^&]*', 'i');

			if (currentURL.match(regex)) {
				currentURL = currentURL.replace(regex, '$1' + paramName[i] + '=' + newParamValue[i]);
			} else {
				const separator = currentURL.includes('?') ? '&' : '?';
				currentURL = currentURL + separator + paramName[i] + '=' + newParamValue[i];
			}
		}

		this.replaceLocationState(currentURL);
		return currentURL;
	}

	public goToTermsOfUse(): void {
		this._router.navigate([`${AppConfig.currentLanguage}/faq`])
	}

	public getQueryParamsByKey(key: string): string {
		return this._currentQueryParams[key];
	}

	public goToMap(): void {
		this._router.navigateByUrl(`${AppConfig.currentLanguage}`);
	}

	public goToCatalog(categoryIds: string = ''): void {
		if (categoryIds) {
			this._router.navigateByUrl(`${AppConfig.currentLanguage}/catalog?category=${categoryIds}`)
		} else {
			this._router.navigate([`${AppConfig.currentLanguage}/catalog`])
		}
	}

	public goToFeedbackPage(): void {
		this._router.navigate([`${AppConfig.currentLanguage}/feedback`])
	}

	public goToAboutPayCity(): void {
		this._router.navigate([`${AppConfig.currentLanguage}/about-pay-city`])
	}

	public goToBenifits(): void {
		this._router.navigate([`${AppConfig.currentLanguage}/premium-benifits`])
	}

	public changeLanguageInUrl(language: string): void {
		const languages = AppConfig.acceptedLanguages;
		let currentUrl = this._router.url;

		for (const checkedLanguage of languages) {
			if (currentUrl.startsWith(`/${checkedLanguage}`)) {
				currentUrl = currentUrl.replace(`/${checkedLanguage}`, '');
				break;
			}
		}

		this._router.navigateByUrl(`/${language}${currentUrl}`, { replaceUrl: true });
	}

	public reload(): void {
		window.location.reload();
	}
}